import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import queryString from "query-string";

function App() {
  const { search } = useLocation();
  const searchStrings = useMemo(() => queryString.parse(search), [search]);
  const { latitude, longitude, lang, title } = searchStrings;
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: 'e84035756552606ebfc78c8f129d0b75',
    lat: latitude,
    lon: longitude,
    lang: lang || 'en',
    unit: 'metric',
  });
  return (
    <ReactWeather
    isLoading={isLoading}
    errorMessage={errorMessage}
    data={data}
    lang="en"
      unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
      locationLabel={title}
    showForecast
  />
  );
}

export default App;
