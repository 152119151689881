import { QueryClient, QueryClientProvider } from "react-query";
import RoutesComponent from "./routes";

export default function App() {
  const queryClient = new QueryClient();

  return (
      <QueryClientProvider client={queryClient}>
        <RoutesComponent />
      </QueryClientProvider>
  );
}
