import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import queryString from "query-string";
import Countdown from "react-countdown";

function App() {
  const { search } = useLocation();
  const searchStrings = useMemo(() => queryString.parse(search), [search]);
  const { title, description, time } = searchStrings;
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}

      {time && parseInt(time) ? (
        <StyledCountDown date={parseInt(time)} />
      ) : null}

      {description ? <Description>{description}</Description> : null}
    </Container>
  );
}

export default App;

const Container = styled.div`
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  color: white;
`;
const Title = styled.h1`
  font-size: 8vw;
  font-weight:100;
`;

const Description = styled.p``;

const StyledCountDown = styled(Countdown)`
  font-size: 8vw;
  font-weight: 800 ;
`;
