import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import queryString from "query-string";

function App() {
  const { search } = useLocation();
  const searchStrings = useMemo(() => queryString.parse(search), [search]);
  const { url } = searchStrings;

  return (
    <Container>
      <Image src={url} />
    </Container>
  );
}

export default App;

const Container = styled.div`
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  color: white;
`;

const Image = styled.div`
  background-image:  ${props => `url(${props.src})`};
  height: 100%;
  width: 100%;
  min-width: 50vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`