import styled from "styled-components";
import Iframe from "react-iframe";
import { useQuery } from "react-query";
import useKiosk from "hooks/useKiosk";
import { getKiosk, getPlaceInfo, getWeather } from "./services";
import { GET_KIOSK, GET_PLACE, GET_WEATHER } from "config/queries";

function App() {
  const kioskId = useKiosk((state) => state.kioskId);
  const setKioskId = useKiosk((state) => state.setKioskId);
  const deleteKiosk = useKiosk((state) => state.deleteKiosk);

  const kioskList = useQuery(
    GET_KIOSK + kioskId,
    () =>
      getKiosk({
        kioskId: kioskId,
      }),
    {
      // Refetch the data every 60 seconds
      refetchInterval: 60 * 1000,
    }
  );

  const placeInfos = useQuery(GET_PLACE + kioskList?.data?.place, () =>
    getPlaceInfo({
      placeId: kioskList?.data?.place,
    })
  );

  const weatherInfo = useQuery(GET_WEATHER + placeInfos?.data?.location?.loc?.[0], () => 
    getWeather(placeInfos?.data?.location?.loc, kioskList?.data?.showHeader),
    {
      // Refetch the data every 1 hours
      refetchInterval: 3600 * 1000,
    }
  )

  return (
    <>
      {!!kioskList?.data?.showHeader ? (
        <HeaderComponent
          logo={placeInfos?.data?.location?.logo}
          weather={weatherInfo?.data?.main?.temp}
          weatherIcon={weatherInfo?.data?.weather[0].icon}
        />
      ) : null}

      <Container>
        {!kioskList?.data && (
          <Input
            autoFocus
            type="text"
            name="kioskId"
            placeholder="Kiosk ID"
            value={kioskId}
            onChange={(e) => setKioskId(e.target.value)}
          />
        )}

        {Array.isArray(kioskList?.data?.widgets) &&
          kioskList.data.widgets.map((k) => (
            <Column width={k.width} height={k.height} key={k.url}>
              <StyledIframe
                url={k.url}
                width="100%"
                height="100%"
                display="block"
                position="relative"
              />
            </Column>
          ))}

        <ResetScreen onClick={() => deleteKiosk()} />
      </Container>
    </>
  );
}

export default App;

const HeaderComponent = ({ logo, weather, weatherIcon }) => {
  return (
    <Header>
      <Logo src={logo} alt="logo" />
      <WeatherComponent weather={weather} weatherIcon={weatherIcon} />
    </Header>
  );
};

const WeatherComponent = ({ weather, weatherIcon }) => {
  return (
    <WeatherContainer>
      {weatherIcon && (
        <WeatherIcon
          width={70}
          height={70}
          src={`weatherIcons/${weatherIcon}.svg`}
          alt="icon"
        />
      )}
      {
   weather && <Temperature>{Math.round(weather)}°C</Temperature>
      }
   
    </WeatherContainer>
  );
};

const WeatherContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WeatherIcon = styled.img``;

const Temperature = styled.h1`
  color: #f7b23b;
  font-size: 8vh ;
`;

const Logo = styled.img`
  height: 8vh;
  width: auto;
`;

const Header = styled.div`
  width: calc(100% - 6vw);
  height: 12vh;
  border-bottom: 5px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3vw;
  padding-right: 3vw;
`;

const Container = styled.div`
  height: 100%;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: black;
  color: white;
`;

const Column = styled.div`
  width: ${(props) => (props.width ? +props.width + "%" : "100%")};
  height: ${(props) => (props.height ? props.height + "%" : "100%")};
`;

const Input = styled.input`
  width: 550px;
  height: 100px;
  text-align: center;
  font-size: 50px;
  border-image-source: linear-gradient(
    rgba(0, 51, 102, 0.8),
    rgba(0, 0, 51, 0.8)
  );
  border-width: 10pt;
  border-image-slice: 1;
  :focus {
    outline: none;
  }
`;

const ResetScreen = styled.div`
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: transparent;
  z-index: 9999;
  bottom: 0;
`;

const StyledIframe = styled(Iframe)`
  overflow: hidden !important;
  border: none;
`;
