import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import queryString from "query-string";
import ReactPlayer from 'react-player'

function App() {
  const { search } = useLocation();
  const searchStrings = useMemo(() => queryString.parse(search), [search]);
  const { url } = searchStrings;
  const arrayFromString = url ? url.split(',') : url
  return (
    <Container>
      {url ? <ReactPlayer
        url={arrayFromString}
        playing
        loop
        controls={false}
        muted
        width={'100%'}
        height={'100%'}
      /> : null}
    </Container>
  );
}

export default App;

const Container = styled.div`
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  color: white;
`;
