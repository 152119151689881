import create from 'zustand'
import { persist } from 'zustand/middleware'
import omit from 'lodash-es/omit'

const useKiosk = create(
  persist(
    (set, get) => ({
      kioskId: null,
      setKioskId: (data) => set({ kioskId: data }),
      deleteKiosk: () => set((state) => omit(state, ['kioskId']), true),
    }),
    {
      name: "KioskLocalStorage",
      getStorage: () => localStorage,
    }
  )
)

export default useKiosk
