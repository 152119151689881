import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CountDownRoutes, { path as countDownPath } from "./countdown";
import WeatherRoutes, { path as weatherPath } from "./weather"
import PlayerRoutes, { path as playerPath } from "./player"
import CarouselRoutes, { path as carouselPath } from "./carousel"
import KioskRoutes from "./kiosk"

export default function RoutesComponent() {
  return (
    <>
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route exact path={countDownPath} element={<CountDownRoutes />} />
            <Route exact path={weatherPath} element={<WeatherRoutes />} />
            <Route exact path={playerPath} element={<PlayerRoutes />} />
            <Route exact path={carouselPath} element={<CarouselRoutes />} />
            <Route exact path={"*"} element={<KioskRoutes />} />
          </Routes>
        </Fragment>
      </BrowserRouter>
    </>
  );
}
