import fetchit from 'lib/fetchit'

import {
    API_KIOSKS,
    API_CMS,
    API_WEATHER_URL,
    API_WEATHER_KEY
} from 'config/api'

export function getKiosk({ kioskId }) {
    if(!kioskId || kioskId.length<5) return
    return fetchit(API_KIOSKS+'/'+kioskId, null, 'GET', null)
}

export function getPlaceInfo({ placeId }) {
    if(!placeId || placeId.length<5) return
    return fetchit(API_CMS+'/'+placeId, null, 'GET', null)
}

export function getWeather(location, showHeader) {
    if (!location || !location[0] || !showHeader) {
        return
    }
    return  fetch(
      API_WEATHER_URL +
        location[0] +
        "&lon=" +
        location[1] +
        "&appid=" +
        API_WEATHER_KEY +
        "&units=metric"
    ).then(res =>
        res.json()
      )
  }
  